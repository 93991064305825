
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import Error from 'next/error';
export default function NotFound() {
    const { t } = useTranslation('common');
    // do not record an exception in Sentry for 404
    return <Error statusCode={404} title={t('page_not_found')}/>;
}

    async function __Next_Translate__getStaticProps__1898874f5dd__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1898874f5dd__ as getStaticProps }
  